import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '@base-component';
import { IUtilitySidebarSettings } from '@interfaces';
import { NbSidebarService } from '@nebular/theme';
import { BehaviorSubject, take, takeUntil, tap } from 'rxjs';
import { UtilitySidebarDirective } from './utility-sidebar.directive';
import { UtilitySidebarService } from './utility-sidebar.service';

@Component({
  selector: 'resplendent-utility-sidebar',
  templateUrl: './utility-sidebar.component.html',
  styleUrls: ['./utility-sidebar.component.scss'],
})
/**
 * This component is responsible for rendering the utility sidebar.
 * The utility sidebar dynamically renders a component that is passed to it by the UtilitySidebarService.
 */
export class UtilitySidebarComponent extends BaseComponent implements OnInit {
  sidebarSettingsSource$ = new BehaviorSubject<IUtilitySidebarSettings>(null);
  sidebarSettings$ = this.sidebarSettingsSource$.asObservable();
  constructor(private utilitySidebarService: UtilitySidebarService, private sidebarService: NbSidebarService) {
    super();
  }

  @ViewChild(UtilitySidebarDirective, { static: true })
  resplendentUtilitySidebarDynamicComponent!: UtilitySidebarDirective;

  public ngOnInit() {
    this.utilitySidebarService
      .getComponentObservable()
      .pipe(
        takeUntil(this.isDestroyed$),
        tap((payload) => {
          this.resplendentUtilitySidebarDynamicComponent.viewContainerRef.clear();
          const component = payload?.component;
          const settings = payload?.settings;
          if (component) {
            this.resplendentUtilitySidebarDynamicComponent.viewContainerRef.createComponent(component);
          }

          if (settings) {
            this.sidebarSettingsSource$.next(settings);
          } else {
            this.sidebarSettingsSource$.next({
              closeButtonIcon: 'arrowhead-right-outline',
              closeButtonStatus: 'basic',
              closeButtonText: 'Close',
              showCloseButton: true,
            });
          }
        }),
      )
      .subscribe();

    this.sidebarService
      .onCollapse()
      .pipe(
        takeUntil(this.isDestroyed$),
        tap(({ tag }) => {
          if (tag === 'utility-sidebar') {
            this.utilitySidebarService.clearComponent();
          }
        }),
      )
      .subscribe();
  }

  close() {
    this.utilitySidebarService
      .getComponentObservable()
      .pipe(
        take(1),
        tap((payload) => {
          const closeCb = payload?.closeCb;
          if (closeCb) {
            closeCb();
          } else {
            this.sidebarService.collapse('utility-sidebar');
          }
        }),
      )
      .subscribe();
  }
}
