import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import '@github/markdown-toolbar-element';
import { NbButtonGroupModule, NbInputModule } from '@nebular/theme';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { BaseComponent } from '@base-component';
import { debounceTime, takeUntil, tap } from 'rxjs';

type WritingMode = 'preview' | 'edit';

@Component({
  selector: 'resplendent-markdown-editor',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NbInputModule,
    NbButtonGroupModule,
    MarkdownModule,
  ],
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MarkdownEditorComponent extends BaseComponent implements OnInit {
  @Input() initialMarkdown = '';
  @Output() markdownChange = new EventEmitter<string>();

  private cd = inject(ChangeDetectorRef);

  writingMode: WritingMode = 'edit';
  markdownControl = new FormControl<string>('', [Validators.maxLength(1000)]);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.markdownControl.setValue(this.initialMarkdown);
    console.log(
      'MarkdownEditorComponent ngOnInit',
      this.initialMarkdown,
      this.markdownControl.value,
    );

    this.markdownControl.valueChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        debounceTime(400),
        tap((markdown) => this.markdownChange.emit(markdown)),
      )
      .subscribe();
  }

  changeWritingMode(event: 'preview' | 'edit') {
    if (!event) {
      return;
    }
    this.writingMode = event;
    this.cd.markForCheck();
  }
}
