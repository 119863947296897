import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbButtonModule, NbIconModule } from '@nebular/theme';
import { NgxColorsModule } from 'ngx-colors';
import { UtilitySidebarComponent } from './utility-sidebar.component';
import { UtilitySidebarDirective } from './utility-sidebar.directive';

@NgModule({
  declarations: [UtilitySidebarComponent, UtilitySidebarDirective],
  imports: [CommonModule, NbButtonModule, NbIconModule, NgxColorsModule],
  exports: [UtilitySidebarComponent],
})
export class UtilitySidebarModule {}
