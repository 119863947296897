import {
  Directive,
  ElementRef,
  NgModule,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[resplendentFocusSelect]',
})
export class FocusSelectDirective implements OnInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.selectRootElement(this.el.nativeElement).focus();
    this.renderer.selectRootElement(this.el.nativeElement).select();
  }
}

@NgModule({
  declarations: [FocusSelectDirective],
  exports: [FocusSelectDirective],
})
export class FocusSelectModule {}
