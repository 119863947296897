import { Component, Input, OnInit } from '@angular/core';
import { ITableModalDataV2 } from '@interfaces';
import { Store } from '@ngxs/store';
import { renderModalCellV2 } from '@pages/dash-v2/helpers';
import { BaseComponent } from '@root/core-components/base-component';
import { IAppStateModel } from '@root/state/app.model';
import { EventQueueService } from '@services';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  GroupCellRendererParams,
} from 'ag-grid-community';
import { filter, first, map, Observable, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'resplendent-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.scss'],
})
export class WidgetTableComponent extends BaseComponent implements OnInit {
  @Input() widgetId: string;
  @Input() isInEditor: boolean;

  widget$ = this.store.select(
    (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
  );

  tableInputData$: Observable<ITableModalDataV2 | undefined>;

  public gridApi: GridApi;

  constructor(private store: Store, private eventQueue: EventQueueService) {
    super();
  }

  ngOnInit(): void {
    if (!this.widgetId) throw new Error('Need widget id to display table');
    this.setTableObservables();
  }

  private setTableObservables() {
    this.tableInputData$ = this.widget$.pipe(
      takeUntil(this.isDestroyed$),
      filter((widget) => !!widget && widget.hasColumnInfo),
      map((widget) => {
        const calculations = widget.calculations;
        const calcList = Object.values(calculations);
        const calcCount = calcList.length;
        if (calcCount > 1 && widget.widgetType === 'table') {
          throw new Error(
            `Table graph can be displayed with and only with one subscribed calculation, instead there were ${calcCount} calculations`,
          );
        }
        const calculation = calcList[0];
        if (!calculation) return;

        let subtitle = calculation.name;
        const calcData = calculation.getActiveData();
        const tableInputData: ITableModalDataV2 = {
          dataTitles: ['No data for this widget'],
          data: [],
          delimiter: null,
          agColumnDefs: [],
          subtitle,
          columnNameMap: calcData?.column_labels,
          color: '#696969',
          stack: 420,
          calcId: calculation.id,
          xAxisDataType: 'string',
        };
        if (!calcData) return tableInputData;
        const pinnedColumns = widget.config.pinnedColumns ?? [];
        const calcModalData = calcData.modal_data;
        if (!calcModalData || calcModalData.length === 0) return tableInputData;
        let dataTitles = calculation.getSelectedColumns();
        if (!dataTitles) return tableInputData;
        tableInputData.dataTitles = dataTitles;
        tableInputData.data = calcModalData;
        let agColumnDefs: ColDef[] = [];
        let currentDataColumns = Object.keys(calcData.modal_data[0]);
        if (widget.config.transposeTableData) {
          dataTitles = currentDataColumns;
        }
        dataTitles.forEach((col) => {
          if (currentDataColumns.includes(col)) {
            agColumnDefs.push({
              cellRenderer: (params: GroupCellRendererParams) => {
                return renderModalCellV2(
                  params.data,
                  params.colDef.field,
                  calculation,
                  widget,
                );
              },
              headerName: calcData.column_labels[col],
              field: col,
              sortable: true,
            });
            if (pinnedColumns.includes(col)) {
              agColumnDefs[agColumnDefs.length - 1].pinned = 'left';
            }
          }
        });
        tableInputData.agColumnDefs = agColumnDefs;
        tableInputData.links = calcData.column_links;
        setTimeout(() => {
          this.gridApi.autoSizeAllColumns();
        }, 500);
        return tableInputData;
      }),
    );
  }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.autoSizeAllColumns();
  }

  exportTable() {
    const widget = this.store.selectSnapshot(
      (state) => (state.app as IAppStateModel).appState.widgets[this.widgetId],
    );
    if (!widget) return;
    const selectedData = widget.calcArray[0].getActiveData();
    if (!selectedData) return;
    const isFiveHundredOrMore = selectedData.modal_data.length >= 500;

    if (isFiveHundredOrMore) {
      this.startLoading();
      this.eventQueue
        .on('GET_FULL_MODAL_DATA')
        .pipe(first(), takeUntil(this.isDestroyed$))
        .subscribe(() => this.stopLoading());
      this.eventQueue.dispatch('DOWNLOAD_BIG_BOI_TABLE_V2', {
        calcId: selectedData.calculation_uuid,
        label: widget.title,
      });
      this.eventQueue.dispatch('SHOW_TOAST', {
        duration: 3000,
        title: 'Preparing your data export...',
        message: 'This can take a few minutes if your dataset is large',
        status: 'info',
      });
    } else {
      this.gridApi.exportDataAsCsv({ fileName: `${widget.title}.csv` });
    }
  }
}
