<nb-card>
  <nb-card-header>
    <h4>Percent Filter</h4>
    <div class="header-actions">
      <nb-icon
        icon="info-outline"
        nbTooltip="The percentage filter: a mathematical magician's wand that transforms colossal datasets into bite-sized chunks of insights. Suppose you have a dataset as vast as the ocean of sales orders and you're itching to discover what slice of this grand pie comes from 'Customer A'. Fear not! That's where our percentage filter steps in, turning data deluge into discernible details. You'd just need to tip your magic wand—select the 'Customer' column, set it equal to 'Customer A', and voila! The curtain lifts, revealing a focused snapshot, a specific percentile of the total sales orders. After taking a bow, save and close the dialog, with the satisfaction of a show well performed. The percentage filter, where data meets drama!"
      ></nb-icon>
      <button nbButton status="success" (click)="saveAndCloseDialog()">
        <nb-icon icon="save-outline"></nb-icon> Save & Close
      </button>
      <resplendent-close-button (click)="closeDialog()"></resplendent-close-button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p class="hint-text">
      The "Percent Filter" represents the data that is returned from this filter. The "All Widget Data" represents the
      total dataset.
    </p>
    <div class="image-container">
      <img src="assets/percent_calculation.svg" alt="percent calculation description" height="100" />
    </div>
    <resplendent-row-filter-gui-v2
      *ngIf="percentFilter"
      [filterObject]="percentFilter"
      [columns]="columns"
      (filterChanged)="updatePercentFilter($event)"
    >
    </resplendent-row-filter-gui-v2>
  </nb-card-body>
</nb-card>
