<div class="metric-info-container">
  <resplendent-pulser
    [nbPopover]="calculationListPopover"
    nbPopoverTrigger="hover"
    [color]="graphStatusColor"
  ></resplendent-pulser>
</div>

<ng-template #calculationListPopover>
  <div class="popover-container">
    <h6>Widget health status</h6>

    <div
      class="list-item-container"
      *ngFor="let status of calculationStatuses$ | async"
    >
      <div class="left-col">
        <resplendent-pulser [color]="status.color"></resplendent-pulser>
        <p>{{ status.title }}</p>
      </div>

      <div class="right-col">
        <p>
          Updated:
          <span>{{ status.latestDataUpdate | timeago }}</span>
        </p>
        <div class="hint-text">
          {{ status.updateIntervalTooltip }}
        </div>
        <div *ngIf="nextPlayThreshold !== null" class="hint-text">
          Sound will play:
          <span>{{ nextPlayThreshold | timeago }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
