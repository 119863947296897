<ng-container *ngIf="widget$ | async as widget">
  <ng-container *ngIf="isInGridster">
    <ng-container *ngIf="widget.gridsterItem">
      <gridster-item
        class="widget-gridster-item"
        [id]="widget.id"
        [item]="widget.gridsterItem"
        [ngClass]="widgetHighlightStateCssClassMap[widget.highlightState]"
        #gridsterItem
      >
        <div
          class="selection-overlay"
          (click)="handleWidgetBodyClick(null)"
        ></div>
        <ng-container *ngTemplateOutlet="component"> </ng-container>
      </gridster-item>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!isInGridster">
    <ng-container *ngTemplateOutlet="component"> </ng-container>
  </ng-container>
</ng-container>

<ng-template #component>
  <div
    class="widget-container"
    *ngIf="widget$ | async as widget"
    (click)="log(widget)"
    [style]="
      widget.widgetType === 'iFrame' &&
      (dashboardDisplayState$ | async) === 'dashConfig'
        ? 'padding: 1rem'
        : ''
    "
  >
    <ng-container *ngIf="!widget.isLoading; else widgetLoading">
      <div class="widget-header" *ngIf="widget.config.showWidgetTitle">
        <div class="widget-title">
          <div
            class="widget-title-text"
            [nbTooltip]="widget.title"
            *ngIf="widget.title"
            [style.font-size.px]="widgetTitleFontSize$ | async"
            [style.line-height.px]="(widgetTitleFontSize$ | async) + 5"
          >
            {{ widget.title }}
          </div>
          <nb-icon
            class="widget-description-icon"
            *ngIf="widget.description"
            icon="info-outline"
            [nbPopover]="widgetDescription"
            nbPopoverTrigger="hover"
          ></nb-icon>
        </div>
      </div>
      <div
        class="chart-container"
        [ngClass]="{
          'chart-container-has-title':
            widget.config.showWidgetTitle,
          'chart-container-no-title':
            !widget.config.showWidgetTitle,
          'chart-container-no-title-and-no-actions':
            !widget.config.showWidgetTitle &&
            !widget.isDataBasedWidget &&
            (dashboardDisplayState$ | async) !== 'dashConfig',
        }"
      >
        <resplendent-chart-v2
          *ngIf="isChartJs"
          (emitWidgetClick)="handleWidgetBodyClick($event)"
          [widgetId]="widget.id"
        ></resplendent-chart-v2>
        <resplendent-widget-table
          *ngIf="isTable"
          [widgetId]="widget.id"
          [isInEditor]="isInEditor"
        ></resplendent-widget-table>
        <resplendent-widget-matrix
          *ngIf="isMatrix"
          class="matrix"
          [widgetId]="widget.id"
          [isInEditor]="isInEditor"
          (emitWidgetClick)="handleWidgetBodyClick($event)"
        >
        </resplendent-widget-matrix>
      </div>
      <div
        class="widget-actions"
        *ngIf="
          (isInGridster && widget.isDataBasedWidget) ||
          (isInGridster && (dashboardDisplayState$ | async) === 'dashConfig')
        "
      >
        <div class="widget-actions-island">
          <!-- Widget status info -->
          <div class="widget-status-indicators">
            <div
              *ngIf="widget.isDataBasedWidget && !isInEditor"
              class="status-row"
            >
              <resplendent-widget-data-indicator
                [widgetId]="widget.id"
                [nextPlayThreshold]="(widgetAudioState$ | async).nextPlayTime"
                (emitResubAttempt)="attemptResub($event)"
                class="metric-info-dot"
              ></resplendent-widget-data-indicator>
              <nb-icon
                class="widget-status-icon"
                *ngIf="widgetHasPreviewData$ | async"
                icon="eye-outline"
                nbPopover="This widget contains preview data."
                nbPopoverTrigger="hint"
              ></nb-icon>
            </div>
            <div *ngIf="!widget.isDataBasedWidget && !isInEditor">
              <!-- Place holder when data indicator not required -->
            </div>
            <nb-icon
              icon="funnel-outline"
              *ngIf="
                widget.getActiveFilterViewId && (activeFilterView$ | async)
              "
              [nbTooltip]="'Filtered by ' + (activeFilterView$ | async).label"
              class="widget-status-icon"
            ></nb-icon>
            <nb-icon
              icon="filter-variable"
              pack="resplendent"
              *ngIf="widgetHasFilterVariables$ | async"
              [nbPopover]="filterVariableStatePopover"
              nbPopoverTrigger="hover"
              class="widget-status-icon"
            ></nb-icon>
            <ng-container
              *ngIf="
                (indicatorsInActionsMenu$ | async) === false ||
                (dashboardDisplayState$ | async) === 'dashboard'
              "
            >
              <ng-container *ngIf="canWidgetPlayThresholdSound()">
                <button
                  *ngIf="canWidgetBeMuted()"
                  nbButton
                  ghost
                  (click)="toggleThresholdAudio()"
                  size="small"
                  [nbTooltip]="
                    (widgetAudioState$ | async).audioMuted ? 'Unmute' : 'Mute'
                  "
                >
                  <nb-icon
                    [icon]="
                      (widgetAudioState$ | async).audioMuted
                        ? 'volume-off-outline'
                        : 'volume-up-outline'
                    "
                  ></nb-icon>
                </button>
                <button
                  nbButton
                  ghost
                  (click)="toggleSnooze()"
                  size="small"
                  [nbTooltip]="
                    (widgetAudioState$ | async).snooze.isSnoozed
                      ? 'Un-snooze'
                      : 'Snooze'
                  "
                  *ngIf="(widgetAudioState$ | async).thresholdWasHit"
                >
                  <nb-icon
                    [icon]="
                      (widgetAudioState$ | async).snooze.isSnoozed
                        ? 'bell-off-outline'
                        : 'bell-outline'
                    "
                  ></nb-icon>
                </button>
                <span
                  *ngIf="(widgetAudioState$ | async).snooze.isSnoozed"
                  class="hint-text"
                  >Snoozing Until:
                  {{
                    (widgetAudioState$ | async).snooze.snoozeUntil
                      | date : 'shortTime'
                  }}</span
                >
              </ng-container>
            </ng-container>
          </div>
          <!-- Widget actions -->
          <div
            class="widget-editor-actions"
            *ngIf="
              (isShared$ | async) === false &&
              (dashboardDisplayState$ | async) === 'dashConfig'
            "
          >
            <button
              nbButton
              ghost
              shape="round"
              (click)="editWidget()"
              nbTooltip="Edit this widget"
              *ngIf="(indicatorsInActionsMenu$ | async) === false"
            >
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
            <button
              nbButton
              ghost
              shape="round"
              [nbPopover]="widgetActionsMenu"
            >
              <nb-icon icon="more-vertical-outline"></nb-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #widgetActionsMenu>
  <div class="menu-container">
    <div class="menu-item" *ngIf="stateService.isGigaAdmin()">
      <button
        nbButton
        size="small"
        fullWidth
        outline
        ghost
        (click)="openCreateTemplateDialog()"
      >
        <nb-icon icon="code-download-outline"></nb-icon> Create Template
      </button>
    </div>
    <div class="menu-item">
      <button
        nbButton
        size="small"
        fullWidth
        outline
        ghost
        (click)="openCopyWidgetDialog()"
      >
        <nb-icon icon="copy-outline"></nb-icon> Advanced Copy
      </button>
    </div>
    <div class="menu-item">
      <button
        nbButton
        size="small"
        fullWidth
        outline
        ghost
        (click)="copyNewGroupWidget()"
      >
        <nb-icon icon="copy-outline"></nb-icon> Copy Widget
      </button>
    </div>
    <div class="menu-item">
      <button
        nbButton
        size="small"
        fullWidth
        outline
        ghost
        (click)="deleteWidget()"
      >
        <nb-icon icon="trash-outline"></nb-icon> Delete Widget
      </button>
    </div>
    <ng-container *ngIf="indicatorsInActionsMenu$ | async">
      <div class="menu-item">
        <button
          nbButton
          ghost
          size="small"
          fullWidth
          (click)="editWidget()"
          nbTooltip="Edit this widget"
        >
          <nb-icon icon="edit-outline"></nb-icon>
          Edit Widget
        </button>
      </div>
      <div
        class="menu-item"
        *ngIf="canWidgetPlayThresholdSound() && canWidgetBeMuted()"
      >
        <button
          nbButton
          ghost
          fullWidth
          (click)="toggleThresholdAudio()"
          size="small"
        >
          <nb-icon
            [icon]="
              (widgetAudioState$ | async).audioMuted
                ? 'volume-off-outline'
                : 'volume-up-outline'
            "
          ></nb-icon>
          {{ (widgetAudioState$ | async).audioMuted ? 'Unmute' : 'Mute' }}
        </button>
      </div>
      <div
        class="menu-item"
        *ngIf="
          canWidgetPlayThresholdSound() &&
          (widgetAudioState$ | async).thresholdWasHit
        "
      >
        <button nbButton ghost fullWidth (click)="toggleSnooze()" size="small">
          <nb-icon
            [icon]="
              (widgetAudioState$ | async).snooze.isSnoozed
                ? 'bell-off-outline'
                : 'bell-outline'
            "
          ></nb-icon>
          {{
            (widgetAudioState$ | async).snooze.isSnoozed
              ? 'Un-snooze'
              : 'Snooze'
          }}
        </button>
      </div>
      <span
        *ngIf="(widgetAudioState$ | async).snooze.isSnoozed"
        class="hint-text"
        >Snoozing Until:
        {{
          (widgetAudioState$ | async).snooze.snoozeUntil | date : 'shortTime'
        }}</span
      >
    </ng-container>
  </div>
</ng-template>

<ng-template #widgetLoading>
  <resplendent-graph-animated-bar-loader></resplendent-graph-animated-bar-loader>
</ng-template>

<ng-template #filterVariableStatePopover>
  <div class="fv-popover-container">
    <div class="hint-text">Filter Variables on this widget</div>
    <div
      *ngFor="let fv of filterVariablesForThisWidget$ | async"
      class="fv-popover-item"
    >
      {{ fv.name }}
      <ul *ngIf="(filterVariableStateForThisWidget$ | async) !== null">
        <li
          *ngFor="
            let variableValue of (filterVariableStateForThisWidget$ | async)[
              fv.id
            ]
          "
        >
          {{
            !!$any(variableValue).label
              ? $any(variableValue).label
              : variableValue
          }}
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #widgetDescription>
  <div class="widget-description">
    <markdown
      [data]="(widget$ | async).description"
      class="markdown-preview"
    ></markdown>
  </div>
</ng-template>
