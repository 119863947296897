import { Injectable } from '@angular/core';
import { IUtilitySidebarComponent, IUtilitySidebarSettings } from '@interfaces';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilitySidebarService {
  private componentSource$ = new BehaviorSubject<IUtilitySidebarComponent>(null);
  private component$ = this.componentSource$.asObservable();

  public setComponent(component: any, settings?: IUtilitySidebarSettings, closeCb?: () => void) {
    this.componentSource$.next({ component, settings, closeCb });
  }

  public getComponentObservable() {
    return this.component$;
  }

  public clearComponent() {
    this.componentSource$.next(null);
  }
}
