<ng-container
  *ngIf="(widget$ | async)?.hasSomeData || noDataWidgetType; else noData"
>
  <div
    *ngIf="showChart"
    echarts
    [options]="chartStarterOptions"
    [merge]="chartOptions$ | async"
    (chartClick)="onChartClick($event)"
    (chartInit)="onChartInit($event)"
    class="chart-container"
    [ngStyle]="{
      width: showChart ? '100%' : '0',
      height: showChart ? '100%' : '0',
    }"
  ></div>
  <ng-container *ngIf="widget$ | async as widget">
    <div
      *ngIf="
        !showChart ||
        (widget.config.overlayCurrentNumber && widget.widgetType === 'line')
      "
      class="no-chart-data"
      [ngClass]="{
        'no-chart-data-overlay': widget.config.overlayCurrentNumber,
        'no-chart-data-no-overlay': !widget.config.overlayCurrentNumber,
      }"
      #componentContainer
      (click)="numberClick()"
    >
      <ng-container
        *ngIf="
          widget.widgetType === 'number' ||
          widget.widgetType === 'staticString' ||
          widget.widgetType === 'dynamicString' ||
          (widget.widgetType === 'line' && widget.config.overlayCurrentNumber)
        "
      >
        <ng-container *ngIf="autoResizeString$ | async as autoResizeString">
          <div
            class="widget-number-container"
            [style.fontSize.px]="autoResizeString.fontSize"
            [style.color]="autoResizeString.color"
          >
            <div class="widget-number">
              {{ autoResizeString.text }}
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="widget.widgetType === 'iFrame'">
        <iframe
          [src]="widget.iFrameUrl | trustUrlString"
          class="iframe"
          height="100%"
          width="100%"
          allow="autoplay"
          ><p>iframes are not supported by your browser.</p></iframe
        >
        <!-- This div covers up the iframe during editing so we can catch mouse events for dragging/resizing -->
        <div *ngIf="isInEditor" class="mouse-catcher"></div>
      </ng-container>
      <ng-container *ngIf="widget.widgetType === 'image'">
        <img *ngIf="widget.imageUrl" class="image" [src]="widget.imageUrl" />
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #noData>
  <div class="no-data">
    <div class="no-data-text">
      <span>No data available</span>
    </div>
  </div>
</ng-template>
