import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { getContrastColor } from '@helpers';

/**
 * Takes a background color hex input and determines if the text on the background should
 * be black or white
 */
@Pipe({
  name: 'blackOrWhiteContrast',
  pure: true,
})
export class BlackOrWhiteContrastPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    if (!value) return value;

    const hexRegEx = /^#([0-9a-f]{6})$/i;
    const isValidHex = hexRegEx.test(value);
    if (!isValidHex) {
      throw new Error(
        `Invalid hex color input "${value}". Accepted values must start with "#" and have exactly 6 hexadecimal numbers following it. If this behavior is limiting feel free to edit this pipe to accept 3 hex values, rgb and whatnot.`,
      );
    }

    return getContrastColor(value);
  }
}

@NgModule({
  declarations: [BlackOrWhiteContrastPipe],
  exports: [BlackOrWhiteContrastPipe],
})
export class BlackOrWhiteContrastModule {}
