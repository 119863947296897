import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'trustUrlString' })
export class TrustUrlStringPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(str: string) {
    // Filter out anything that doesn't have https in it
    if (!str.includes('https://')) return this.sanitizer.bypassSecurityTrustResourceUrl('');
    return this.sanitizer.bypassSecurityTrustResourceUrl(str);
  }
}

@NgModule({
  declarations: [TrustUrlStringPipe],
  exports: [TrustUrlStringPipe],
})
export class TrustUrlStringModule {}
