<nb-form-field>
  <nb-icon
    *ngIf="getIconType(this.selectedColumn)"
    nbPrefix
    [icon]="getIconType(this.selectedColumn)"
    pack="eva"
    [nbTooltip]="'Column Type: ' + getColumnTypeName(this.selectedColumn)"
  ></nb-icon>
  <input
    [formControl]="inputFormControl"
    nbInput
    type="text"
    [placeholder]="placeholder"
    [nbAutocomplete]="columnSearch"
    [fieldSize]="fieldSize"
    fullWidth
  />
  <button nbSuffix nbButton ghost size="small">
    <nb-icon icon="close-outline" (click)="clearInput()"></nb-icon>
  </button>
</nb-form-field>

<nb-autocomplete
  #columnSearch
  [handleDisplayFn]="showColumnName"
  [activeFirst]="true"
  (selectedChange)="onColumnSelected($event)"
>
  <nb-option
    *ngFor="let column of filteredColumns$ | async"
    [value]="column"
    [disabled]="disabledColumns.includes(column.name)"
  >
    <div class="flex gap-1 items-center">
      <nb-icon
        [icon]="getIconType(column.name)"
        class="data-type-icon"
      ></nb-icon>
      {{ column.alias ? column.alias : column.name }}
    </div>
  </nb-option>
</nb-autocomplete>
