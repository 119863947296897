import { Component, Input } from '@angular/core';
import { StatusDotColor } from '@interfaces';

@Component({
  selector: 'resplendent-pulser',
  templateUrl: './pulser.component.html',
  styleUrls: ['./pulser.component.scss'],
})
export class PulserComponent {
  @Input() color: StatusDotColor;

  get dotColor(): 'green' | 'yellow' | 'red' {
    switch (this.color) {
      case StatusDotColor.green:
        return 'green';
      case StatusDotColor.orange:
        return 'yellow';
      case StatusDotColor.red:
        return 'red';
    }
  }
}
