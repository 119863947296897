import { calcStringDateToDate } from '@helpers';
import { IColumnLink } from '@interfaces';
import { CalculationStateObject } from '@root/state/state-model-objects/calculation.state-model';
import { WidgetStateObject } from '@root/state/state-model-objects/widget.state-model';

const DEFAULT_NUMBER_FORMAT = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

function formatDate(
  date: Date,
  format: string | undefined,
  isDateTime: boolean,
): string {
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
    return ''; // or handle invalid dates differently
  }

  if (!format || format === 'local') {
    return isDateTime ? date.toLocaleString() : date.toLocaleDateString();
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  let formattedDate = format
    .replace('month', String(month))
    .replace('day', String(day))
    .replace('year', String(year));

  // Support multiple datetime format patterns
  if (
    isDateTime &&
    (format.includes('hour:minute:second') || format.includes('HH:mm:ss'))
  ) {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    formattedDate += ` ${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
}

function formatNumber(value: number): string {
  return value.toLocaleString('en-US', DEFAULT_NUMBER_FORMAT);
}

export function renderModalCellV2(
  row: { [key: string]: any },
  col: string,
  calculation: CalculationStateObject,
  widget: WidgetStateObject,
): string {
  const calcData = calculation.getActiveData();
  const links = calcData.column_links;
  const modifierLinks = calcData?.extras?.links;
  const dtypes = calculation.getColumnDataTypes();
  const config = widget.config;

  let value = row[col];
  let prefix = '';
  let suffix = '';
  if (config.columnsWithDollarPrefix.includes(col)) {
    prefix = '$';
  }
  if (config.columnsWithPercentSuffix.includes(col)) {
    suffix = '%';
  }

  if (
    dtypes &&
    (dtypes[col] === 'datetime' || dtypes[col] === 'date') &&
    value
  ) {
    const date = calcStringDateToDate(value);
    if (date) {
      value = formatDate(
        date,
        config.drilldownDateFormat,
        dtypes[col] === 'datetime',
      );
    }
  }

  const modifierLinkColName = `__link__.${col}`;
  const hasModifierLink = !!modifierLinks?.[row[modifierLinkColName]];
  const hasLinks = !!links?.[col] || hasModifierLink;

  if (dtypes && (dtypes[col] === 'float' || dtypes[col] === 'int') && value) {
    value = formatNumber(value);
  } else {
    value = String(value ?? ''); // Explicitly convert to string, handle null/undefined
  }

  value = prefix + value + suffix;

  const linkItems: IColumnLink[] = hasModifierLink
    ? modifierLinks[row[modifierLinkColName]]
    : links[col];

  if (!hasLinks || !linkItems) {
    return value;
  }

  let link = '';
  for (const item of linkItems) {
    link += item.type === 'string' ? item.value : String(row[item.value] ?? ''); // Handle undefined values
  }

  return `<a href="${link}" target="_blank">${value}</a>`;
}
