import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@base-component';
import { ColumnDataType, IDataStreamColumn } from '@interfaces';
import { BehaviorSubject, Observable, startWith, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'resplendent-column-autocomplete',
  templateUrl: './column-autocomplete.component.html',
  styleUrls: ['./column-autocomplete.component.scss'],
})
export class ColumnAutocompleteComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() columns: IDataStreamColumn[];
  @Input() selectedColumn: string | null = null;
  @Input() fieldSize: 'tiny' | 'small' | 'medium' | 'large' = 'medium';
  @Input() disabledColumns: string[] = [];
  @Input() placeholder = 'Select a column';
  @Output() selectedColumnChange = new EventEmitter<string | null>();

  filteredColumnsSource$ = new BehaviorSubject<IDataStreamColumn[]>([]);
  filteredColumns$ = this.filteredColumnsSource$.asObservable();
  inputFormControl: FormControl;

  iconTypeMap: { [key in ColumnDataType]: string } = {
    string: 'text-outline',
    int: 'hash-outline',
    float: 'hash-outline',
    boolean: 'toggle-right-outline',
    bool: 'toggle-right-outline',
    datetime: 'calendar-outline',
    date: 'calendar-outline',
    timedelta: 'clock-outline',
  };

  prettyNameMap: { [key in ColumnDataType]: string } = {
    string: 'Text',
    int: 'Whole Number',
    float: 'Decimal Number',
    boolean: 'Boolean',
    bool: 'Boolean',
    datetime: 'Date Time',
    date: 'Date',
    timedelta: 'Time Delta',
  };

  constructor() {
    super();
  }

  ngOnInit() {
    this.inputFormControl = new FormControl('');
    this.sortColumns();
    this.initializeColumns();

    this.inputFormControl.valueChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        startWith(''),
        tap((value: string | IDataStreamColumn) => {
          if (typeof value !== 'string') {
            if ('name' in value) {
              this.selectedColumn = value.name;
              this.selectedColumnChange.emit(value.name);
            }
          } else {
            this.filteredColumnsSource$.next(this.filter(value));
          }
        }),
      )
      .subscribe();

    if (this.selectedColumn) {
      const selectedColumn = this.columns.find(
        (column) => column.name === this.selectedColumn,
      );
      this.inputFormControl.setValue(
        selectedColumn.alias ?? selectedColumn.name,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.columns || changes.disabledColumns || changes.selectedColumn) {
      this.sortColumns();
      this.initializeColumns();
    }
  }

  private sortColumns() {
    // sort the columns by name/alias and put the disabled columns at the end
    const enabledColumns = this.columns
      .filter((col) => !this.disabledColumns.includes(col.name))
      .sort((a, b) => {
        const aName = a.alias ? a.alias : a.name;
        const bName = b.alias ? b.alias : b.name;
        return aName.localeCompare(bName);
      });
    const disabledColumns = this.columns
      .filter((col) => this.disabledColumns.includes(col.name))
      .sort((a, b) => {
        const aName = a.alias ? a.alias : a.name;
        const bName = b.alias ? b.alias : b.name;
        return aName.localeCompare(bName);
      });

    this.columns = [...enabledColumns, ...disabledColumns];
  }

  private initializeColumns() {
    if (!this.filteredColumnsSource$.value.length) {
      this.filteredColumnsSource$.next(this.columns);
    }
  }

  getIconType(column: string): string | null {
    const selectedColumn = this.columns.find((col) => col.name === column);
    if (!selectedColumn) return null;
    return this.iconTypeMap?.[selectedColumn?.dataType] ?? null;
  }

  getColumnTypeName(column: string): string | null {
    const selectedColumn = this.columns.find((col) => col.name === column);
    if (!selectedColumn) return null;
    return this.prettyNameMap[selectedColumn.dataType] ?? null;
  }
  onColumnSelected(column: IDataStreamColumn) {
    this.filteredColumnsSource$.next(this.filter(''));
  }

  private filter(value: string | IDataStreamColumn): IDataStreamColumn[] {
    if (typeof value !== 'string') return this.columns;
    const filterValue = value.toLowerCase();
    if (
      this.columns.find((col) => filterValue === col.name.toLowerCase()) &&
      !this.inputFormControl.dirty
    ) {
      return this.columns;
    }
    return this.columns.filter((column) => {
      const columnName = column.alias ? column.alias : column.name;
      return columnName.toLowerCase().includes(filterValue);
    });
  }

  showColumnName(column: string | IDataStreamColumn) {
    if (typeof column === 'string') return column;
    return column.alias ? column.alias : column.name;
  }

  clearInput() {
    this.inputFormControl.setValue('');
    this.selectedColumn = null;
    this.selectedColumnChange.emit(null);
  }
}
