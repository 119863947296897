<div class="editor-container">
  <div class="flex justify-between mb-2">
    <div>
      <nb-button-group
        (valueChange)="changeWritingMode($event?.[0])"
        size="tiny"
      >
        <button nbButtonToggle value="edit" pressed>Edit</button>
        <button nbButtonToggle value="preview">Preview</button>
      </nb-button-group>
    </div>
    <markdown-toolbar
      for="textarea_id"
      class="toolbar"
      *ngIf="writingMode === 'edit'"
    >
      <md-bold class="md-tool"><i class="bi bi-type-bold"></i></md-bold>
      <md-italic class="md-tool"><i class="bi bi-type-italic"></i></md-italic>
      <md-header class="md-tool"><i class="bi bi-type-h3"></i></md-header>
      <md-quote class="md-tool"><i class="bi bi-quote"></i></md-quote>
      <md-code class="md-tool"><i class="bi bi-code"></i></md-code>
      <md-link class="md-tool"><i class="bi bi-link"></i></md-link>

      <md-unordered-list class="md-tool md-tool-with-border"
        ><i class="bi bi-list-ul"></i
      ></md-unordered-list>
      <md-ordered-list class="md-tool"
        ><i class="bi bi-list-ol"></i
      ></md-ordered-list>
    </markdown-toolbar>
  </div>
  <textarea
    nbInput
    id="textarea_id"
    fieldSize="large"
    class="markdown-editor"
    fullWidth
    *ngIf="writingMode === 'edit'"
    [formControl]="markdownControl"
  ></textarea>
  <markdown
    *ngIf="writingMode === 'preview'"
    [data]="markdownControl.value"
    class="markdown-preview"
  ></markdown>
  <div class="markdown-supported">
    <i class="bi bi-markdown"></i>
    <a href="https://www.markdownguide.org/basic-syntax/" target="_blank">
      Markdown supported
    </a>
  </div>
</div>
