import { Component, Input, OnInit } from '@angular/core';
import { IDataStreamColumn, IPandasFilterV2 } from '@interfaces';
import { NbDialogRef } from '@nebular/theme';
import { newDefaultAdvancedFilter } from '../helpers';

@Component({
  selector: 'resplendent-percentage-filter-dialog',
  templateUrl: './percentage-filter-dialog.component.html',
  styleUrls: ['./percentage-filter-dialog.component.scss'],
})
export class PercentageFilterDialogComponent implements OnInit {
  @Input() percentFilter: IPandasFilterV2 | null;
  @Input() columns: IDataStreamColumn[];

  constructor(private dialogRef: NbDialogRef<PercentageFilterDialogComponent>) {}

  ngOnInit() {
    if (!this.percentFilter) {
      this.percentFilter = newDefaultAdvancedFilter();
    }
  }

  updatePercentFilter(updatedFilter: IPandasFilterV2) {
    this.percentFilter = updatedFilter;
  }

  saveAndCloseDialog() {
    this.dialogRef.close(this.percentFilter);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
